import React from 'react';
import { css } from '@emotion/core';
import { CardList, CardListItem } from "./card";
import mq from "../defaults/styles/mediaquerys";
import { color } from "../defaults/styles/theme";
import PartnerCard from "./card-partner";
import StartupCard from "./card-startup";
import { wrapper } from "../defaults/styles/elements";


export const CardGroup = ({ data, table }) => {
    return (
        <>
            <div
                css={[
                    wrapper,
                    css`
              font-size: 0.85em;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 0.075em;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                background: ${color.main_dark};
                width: 100%;
                height: 1px;
                opacity: .28;
                ${mq[0]} {
                  width: calc(100% - 1rem);
                }
              }
            `,
                ]}
            >
                <p
                    css={css`
              background: ${color.main_light};
              z-index: 1;
              position: relative;
              display: inline;
              padding: 0 1rem;
              margin-left: -1rem;
            `}
                >
                    { data.fieldValue } {table ? ' ' + table[0] : '' }
                </p>
            </div>
            <CardList
                css={css`
            padding-top: 0;
            margin-top: 4rem;
            ${mq[2]} {
              padding-top: 0;
              margin-top: 1.5rem;
            }
          `}
            >
                {data.edges.map(({ node }, index) => (
                    <CardListItem key={index}>
                        { node.table === 'partners' ? (
                            <PartnerCard data={node.data} />
                        ) : node.table === 'startups' ? (
                            <StartupCard data={node.data} id={ node.id } recordId={ node.recordId } />
                        ) : '' }
                    </CardListItem>
                ))}
                <CardListItem placeholder_item></CardListItem>
                <CardListItem placeholder_item></CardListItem>
                <CardListItem placeholder_item></CardListItem>
            </CardList>
        </>
    )
}