import React from "react";

import {
  Card,
  CardLinkWrapper,
  CardMediaLogo,
  CardContent,
  CardTitle,
  CardUrl,
  CardLocation,
  CardType
} from "../main/card"

import {
  Adac,
  Agc,
  Arena2036,
  Basf,
  Bleistahl,
  Bosch,
  Bp,
  Covea,
  Daimler,
  Dpdhl,
  Dxc,
  Eberspaecher,
  Faurecia,
  Hyundai,
  Jardines,
  Linde,
  Motherson,
  Murata,
  Pnp,
  Porsche,
  Posteitaliane,
  Rrps,
  Sekisui,
  Tsystems,
  Tuev,
  Unistuttgart,
  Wabco,
  Webasto,
  Wieland,
  Yanfeng,
  Zfwabco,
} from "../defaults/assets/logos/partnerlogos/index"

const PartnerIcons = {
  ARENA2036: Arena2036,
  Daimler: Daimler,
  "Plug and Play": Pnp,
  "Universität Stuttgart": Unistuttgart,
  Porsche: Porsche,
  BASF: Basf,
  Bosch: Bosch,
  DPDHL: Dpdhl,
  DXC: Dxc,
  Motherson: Motherson,
  "Rolls-Royce Power Systems": Rrps,
  "T-Systems": Tsystems,
  Wabco: Wabco,
  Webasto: Webasto,
  "ZF Wabco": Zfwabco,
  ADAC: Adac,
  AGC: Agc,
  BP: Bp,
  "Bleistahl Produktions GmbH & Co. KG": Bleistahl,
  Covéa: Covea,
  Eberspächer: Eberspaecher,
  Faurecia: Faurecia,
  Hyundai: Hyundai,
  "Jardine Matheson": Jardines,
  Linde: Linde,
  Murata: Murata,
  "Poste Italiane": Posteitaliane,
  "Sekisui s-lec": Sekisui,
  "TÜV Rheinland": Tuev,
  "Wieland Group": Wieland,
  "Yanfeng Automotive Interiors": Yanfeng,
}




const PartnerCard = ({ className, data, logo }) => {
    let Icon = null;
    let path_url = data.name
      .replace(/\./g, "")
      .replace(/&/g, "")
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/é/g, "e")
    if (PartnerIcons[data.name] !== undefined) {
      Icon = PartnerIcons[data.name]
    }
    
    return (
      <CardLinkWrapper
        className={className}
        active="true"
        cardLink={`/partners/` + decodeURIComponent(path_url)}
      >
        <Card>
          <CardMediaLogo logo={<Icon />} />
          <CardContent>
            <CardTitle>{data.name}</CardTitle>
            <CardLocation country={data.hq_country} city="" />
            <CardType type={data.type} />
            <CardUrl url={data.url} />
          </CardContent>
        </Card>
      </CardLinkWrapper>
    )
}

export default PartnerCard;