import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core";
import Layout from "../components/layout"
import PressFooter from "../components/pressfooter"
import { TitleHeader, Banner } from "../components/defaults/styles/elements"
import { color } from "../components/defaults/styles/theme"
import { Loader } from "../components/defaults/assets/svg/mobilenav"
import { CardGroup } from '../components/main/card_group';
import { isLoggedIn } from "../services/auth"


const seo = {
  frontmatter: {
    title: "Startups",
  },
}

const Startups = ({data}) => {
  const groups = [
    data.allAirtable.group[1],
    data.allAirtable.group[2],
    data.allAirtable.group[0],
  ]

  return (
    <Layout seo={seo}>
      <TitleHeader
        title="Startups of Program 8 (and beyond)"
        subtitle="<p>Each program, our Ventures Team scouts out the most disruptive (in a good way) and forward thinking startups out there. This program was certainly no exception and representing 11+ nations, we are proud to have each of the teams below as collaborators on our platform.</p><p>Spanning the verticals of mobility, production and enterprise, the startups range from: crypto economy to voice enhancement, digital transformation to immersive workspaces, speech analytics, blockchain and more! Together with the business units of our corporate partners, over the last 100 days they have successfully completed a PoC, pilot project or implementation – driving innovation forward! Enjoy checking out their profiles below.</p>"
        icon={<Loader />}
      />
      {!isLoggedIn() ? (
        <Banner
          type="lock"
          content="<p>Additional startup information is exclusively published for partners of STARTUP AUTOBAHN powered by Plug and Play.</p><p>Access Codes can be requested by registering for the All-Access Pass <a href='/register'>here</a>.</p>"
          css={css`
            z-index: 1;
            position: relative;
            a {
              white-space: nowrap;
            }
          `}
        />
      ) : (
        ""
      )}
      <Banner
        type="info"
        content="<p>Although the information below has been curated with the utmost attention to every detail, the STARTUP AUTOBAHN team cannot guarantee for its flawlessness.</p><p>If you would like to report mistakes or request changes please reach out to <a href=mailto:marketing-ger@pnptc.com>marketing-ger@pnptc.com</a></p>"
        css={css`
          z-index: 1;
          position: relative;
          a {
            white-space: nowrap;
          }
        `}
      />

      <section
        css={css`
          background: ${color.main_light};
          padding-top: 7rem;
          margin-top: -2.5rem;
        `}
      >
        {groups.map((edges, index) => (
          <CardGroup
            data={edges}
            key={index}
            table={data.allAirtable.distinct}
          />
        ))}
      </section>
      <PressFooter theme={color.main_light} />
    </Layout>
  )
}

export const query = graphql`
  query startups {
    allAirtable(filter: {data: {include: {eq: true}}, table: {eq: "startups"}}, sort: { fields: data___sort_startup, order: ASC }) {
      group(field: data___vertical) {
        edges {
          node {
            id
            data {
              url
              vertical
              country
              city
              display_company
              startup
              startup_program
            }
            table
            recordId
          }
        }
        fieldValue
      }
      distinct(field: table)
    }
  }
`


export default Startups;