import React from "react";
import { css } from '@emotion/core';

import {
  Card,
  CardLinkWrapper,
  CardMediaLogo,
  CardContent,
  CardTitle,
  CardUrl,
  CardLocation,
  CardType,
  CardProgram,
} from "../main/card"
import { color } from "../defaults/styles/theme";

const StartupCard = ({ className, data, id, recordId }) => {
    let url = data.url.replace(/(https:\/\/)/g, '').replace(/(http:\/\/)/g, '').replace(/\//g, '')
    return (
      <CardLinkWrapper
        className={className}
        cardLink={`/all-access/startups/` + id}
        active='true'
      >
        <Card>
          <CardMediaLogo recordId={recordId} />
          <CardContent>
            <CardTitle>{data.startup}</CardTitle>
            <CardLocation country={data.country} city={data.city} />
            <CardProgram program={data.startup_program}/>
            <CardType type={data.vertical} css={css`
              background: ${
                data.vertical[0] === 'Mobility' ? color.orange
                : data.vertical[0] === 'Production' ? color.red
                : data.vertical[0] === 'Enterprise 2.0' ? color.blue
                : 'black'
              };
              color: white;
            `}/>
            <CardUrl url={url} orig_url={data.url} />
          </CardContent>
        </Card>
      </CardLinkWrapper>
    )
}

export default StartupCard;